import { render, staticRenderFns } from "./full-weight.vue?vue&type=template&id=64342ece&scoped=true&"
import script from "./full-weight.vue?vue&type=script&lang=js&"
export * from "./full-weight.vue?vue&type=script&lang=js&"
import style0 from "./full-weight.vue?vue&type=style&index=0&id=64342ece&prod&scoped=true&lang=css&"
import style1 from "@/pages/visita/tabs/reporte/css/reporte.css?vue&type=style&index=1&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64342ece",
  null
  
)

export default component.exports